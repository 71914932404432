// import languages from '@/data/i18n/languages.json';
import { Activity, ActivityType, Category, Role, SubCategory, UserDefinedActivity } from '@/store/entities';
import { useMetadata } from '@/use/use-metadata';
import { useSubdomain } from '@/use/use-subdomain';
import { hasValidRoles, Roles, webApplicationRoles, combineUserAndOrgRoles, hasRoleInUserOrOrg } from '@packages/roles';
import { useRouter, useState } from '@u3u/vue-hooks';
import { computed, ref } from '@vue/composition-api';
import to from 'await-to-js';
import { ObjectID } from 'bson';
import dasherize from 'dasherize';
import { document, window } from 'global';
import { groupBy, intersection, keyBy, keys, mapValues, orderBy, upperFirst } from 'lodash';
import Vue from 'vue';
import { useI18n } from 'vue-composable';
import { ACTION_TYPE_ENUM, ACTIVITY_ENUM, ActivityTypes } from '@packages/mongodb';
import { useStorage, StorageTypes } from '@/services/storage';
import { flatten } from 'lodash';
import { setAccessPerLanguage } from '@/data/i18n/accessPerLanguage';
import { entityPerLanguages } from '@/data/i18n/accessPerLanguage';
import { isUserHasOrganization } from '@/utils/organizationHelpers';
import { logutFromClalit, userAuditLogs } from '@/services/api';
import { logout } from '@/plugins/auth';
import confetti from 'canvas-confetti';
import { environment } from '@/env';
import { getLanguages } from '@/data/i18n/languages';
import { ALLOWED_LANGUAGES } from '@packages/mongodb';
import moment from 'moment';
import customCursorImage from '@/media/images/custom-cursor.json';
import { CURSOR_SIZE, CURSOR_TYPE } from '@/store/modules/app';
import uuidv4 from 'uuid';
import { UAParser } from 'ua-parser-js';

const DEFAULT_TEXT_CARD_STYLE_SETTING_ENUM = {
  defaultFontSize: 40,
  defaultGridTilePadding: 16,
};

const textCardStyleSettings = [
  { width: 75, fontSize: 10, padding: 2 },
  { width: 90, fontSize: 11, padding: 2 },
  { width: 110, fontSize: 12, padding: 3 },
  { width: 130, fontSize: 13, padding: 3 },
  { width: 150, fontSize: 14, padding: 4 },
  { width: 170, fontSize: 16, padding: 5 },
  { width: 190, fontSize: 18, padding: 6 },
  { width: 210, fontSize: 19, padding: 6 },
  { width: 230, fontSize: 20, padding: 8 },
  { width: 250, fontSize: 22, padding: 9 },
  { width: 270, fontSize: 24, padding: 9 },
  { width: 300, fontSize: 26, padding: 10 },
  { width: 400, fontSize: 30, padding: 11 },
  { width: 500, fontSize: 36, padding: 13 },
];

export const isUserHasPermissonToAccessContent = () => {
  const { user } = useState('auth', ['user']);
  return hasValidWebRole(user.value) && user.value?.organization?.websiteAccess?.includes(getCurrentWebsiteId(false));
};

export async function waitForReadystate() {
  if (typeof document !== 'undefined' && document.readyState !== 'complete') {
    await new Promise((resolve: any) => {
      const cb = () => {
        window.requestAnimationFrame(() => {
          resolve();
        });
        window.removeEventListener('load', cb);
      };
      window.addEventListener('load', cb);
    });
  }
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitForNextFrame() {
  return new Promise((resolve: any) => {
    window.requestAnimationFrame(() => {
      resolve();
    });
  });
}

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function getGtmEventLabel(actionName: string, actionId: any) {
  return `${actionName?.replace(/ /g, '-')}_${actionId}`;
}

export function getUserRolesIds() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.roles.map((role) => role.id);
}

export function hasRole(roleName) {
  const roleId = Role.all().find((role) => role['name'] === roleName)?.['_id'];
  const userRolesIds = getUserRolesIds();
  return userRolesIds && userRolesIds.length > 0 && userRolesIds.includes(roleId);
}

// export function isAdminUser() {
//   const roleId = Role.all().find((role) => role['name'] === Roles.ADMIN)['_id'];
//   const { user: currentUser } = useState('auth', ['user']);

//   return hasRoleInUserOrOrg(currentUser, roleId);
// }

// We add this tempContributor for few weeks only. due to issue in contributor role.
export function isAdminUser() {
  const roleId = Role.all().find((role) => role['name'] === Roles.ADMIN)['_id'];
  const { user: currentUser } = useState('auth', ['user']);

  return hasRoleInUserOrOrg(currentUser, roleId) || isTmpContributorUser();
}

export function isSubscribedUser() {
  const roleId = Role.all().find((role) => role['name'] === Roles.SUBSCIBER)['_id'];
  const { user: currentUser } = useState('auth', ['user']);
  return hasRoleInUserOrOrg(currentUser, roleId);
}

export const isContributorUser = () => {
  return hasRole(Roles.COGNISHINE_CONTRIBUTOR);
};

export const isTmpContributorUser = () => {
  return hasRole(Roles.TMP_CONTRIBUTOR);
};

export const isAdminTextEditorUser = () => {
  return hasRole(Roles.ADMIN_TEXT_EDITOR);
};

export const isOrgContributor = () => {
  return hasRole(Roles.ORG_CONTRIBUTOR);
};

export const isAdminOrContributorOrAdminTextEditorUserOrOrgContributor = () => {
  return isAdminUser() || isContributorUser() || isAdminTextEditorUser() || isOrgContributor();
};

export function hasValidWebRole(user) {
  const hasValidRole = hasValidRoles(combineUserAndOrgRoles(user), webApplicationRoles, Role.all());
  return hasValidRole;
}

export function userHasShowExplosionsFeedbacks() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.preferences?.showExplosionsFeedbacks || isNotLoggedInUser();
}

export function userHasPlayFeedbackSounds() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.preferences?.playFeedbackSound || isNotLoggedInUser();
}

export function isUserValidForAttachedRoles(user, attachedRoles) {
  if (!attachedRoles || attachedRoles.length === 0) return true;
  const hasValidRole = hasValidWebRole(user);

  return (
    !attachedRoles?.length ||
    (hasValidRole &&
      intersection(
        attachedRoles?.map((role) => role.toString()),
        combineUserAndOrgRoles(user)?.map((role) => role.id.toString())
      ).length > 0)
  );
}

export function getUserOrganization() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser?.value?.organization;
}

export function isPayingUser() {
  const { user: currentUser } = useState('auth', ['user']);
  return (
    currentUser.value != null &&
    (isAdminUser() ||
      (isUserHasOrganization() && hasValidWebRole(currentUser.value)) ||
      (hasValidWebRole(currentUser.value) &&
        ((currentUser.value.plan != null && currentUser.value.purchase != null) || currentUser.value.externalPaymentPlan)))
  );
}

export function isPayingUserTemp() {
  const { user: currentUser } = useState('auth', ['user']);
  return (
    currentUser.value != null &&
    (isAdminUser() ||
      isUserHasOrganization() ||
      (hasValidWebRole(currentUser.value) &&
        ((currentUser.value.plan != null && currentUser.value.purchase != null) || currentUser.value.externalPaymentPlan)))
  );
}

export function isPromotionsEntitledUser() {
  const { user: currentUser } = useState('auth', ['user']);
  return !currentUser.value || !hasValidWebRole(currentUser.value);
}

export function isRegisteredUser() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser.value != null;
}

export function isVerifiedUser() {
  const { user: currentUser } = useState('auth', ['user']);
  return currentUser.value != null && currentUser.value.verified != null && currentUser.value.verified;
}

export function routeHasQueryParams(route) {
  return route.value.query.constructor === Object && Object.entries(route.value.query).length > 0;
}

export function getSubdomainsFromQueryParam(subDomainQueryParam) {
  const subdomainsSelected = Array.isArray(subDomainQueryParam) ? subDomainQueryParam : [subDomainQueryParam];
  const subdomainsIds = computed(() =>
    subdomainsSelected.map((subdomain) => {
      const sub = computed(() => upperFirst(String(subdomain).replace(/-([a-z])/g, (g) => g[1].toUpperCase())));
      return sub;
    })
  );
  const result = subdomainsIds.value.map((subdomainId) => useSubdomain({ subdomainId }).subdomain);
  return result;
}

export function keyToUrlKey(key) {
  return dasherize(key).toLocaleLowerCase();
}

export function isSubdomainPage(route) {
  return route.value.query.subdomainPage != null && route.value.query.subdomainPage === 'true';
}

export function getFallbackLocale() {
  // const { languages } = useState('language', ['languages']);
  return getLanguages()?.find((lang) => lang.language?.fallback === true)?.locale;
}

export const getCurrentLanguageObject = (shouldGiveInheritanceInterface = true) => {
  const { route } = useRouter();

  const availableLang = getLanguages();
  const currentLanguage = availableLang?.find((lang) => lang.locale === route.value.params.lang);
  if (shouldGiveInheritanceInterface && currentLanguage?.inheritInterfaceFrom) {
    return currentLanguage?.inheritInterfaceFrom;
  } else {
    return currentLanguage;
  }
};

export function getCurrentLanguage(shouldGiveInheritanceInterface = false) {
  const { route } = useRouter();
  if (route.value.params.lang && shouldGiveInheritanceInterface) {
    const currentLanguage = getCurrentLanguageObject();
    if (currentLanguage?.locale) {
      return currentLanguage?.locale;
    }
  }
  return route.value.params.lang;
}

export function isPrivateWebsite() {
  const currentLanguage = getCurrentLanguageObject(false);
  return !currentLanguage.isPublic;
}

export const getCurrentLanguageWithoutWebsite = () => {
  const availableLang = getLanguages();
  if (availableLang.length) {
    // return availableLang.find((lang) => lang?.locale === getCurrentLanguage())?.language?.languageCode;
    return getCurrentLanguageObject()?.language?.languageCode;
  }
  return null;
};

export const getCurrentLanguageWithoutLanguage = () => {
  const availableLang = getLanguages();
  if (availableLang.length) {
    // return availableLang.find((lang) => lang?.locale === getCurrentLanguage())?.language?.languageCode;
    return getCurrentLanguageObject()?.website;
  }
  return null;
};

export const getCurrentLanguageForIntialLoad = () => {
  const language = window.location?.pathname?.split?.('/')?.[1];
  if (language?.split?.('-')?.length === 2) {
    return language;
  } else {
    return null;
  }
};

export const getCurrentWebsiteId = (shouldGiveInheritanceInterface = true) => {
  const availableLang = getLanguages();
  if (availableLang.length) {
    // return availableLang.find((lang) => lang?.locale === getCurrentLanguage())?._id;
    return getCurrentLanguageObject(shouldGiveInheritanceInterface)?._id;
  }
  return null;
};

export const getCurrentLanguageForPrimaryWebSite = (shouldGiveInheritanceInterface = true) => {
  const currentLang = getCurrentLanguageObject(shouldGiveInheritanceInterface);
  return getLanguages()?.find((lang) => lang.language._id === currentLang.language._id && lang.isPrimaryWebsite === true)?.locale;
};

export function isCurrentLanguageRTL() {
  const { route } = useRouter();
  const { languages } = useState('language', ['languages']);

  return getLanguages()?.find((lang) => lang.locale === route.value.params.lang)?.language?.rtl;
}

export function getEntityFieldLocaleText(field) {
  const { route } = useRouter();
  const fallbackLocale = getFallbackLocale();
  return field?.[getCurrentLanguageWithoutWebsite()] ? field?.[getCurrentLanguageWithoutWebsite()] : field[fallbackLocale];
}

export function getUserDefinedActivity(activityId) {
  return UserDefinedActivity.query().newQuery().whereId(activityId).withAllRecursive().first() as any;
}
export function getActivity(activityId) {
  const activityLoaded = Activity.query().newQuery().whereId(activityId).withAllRecursive().first();

  const activity = JSON.parse(JSON.stringify(activityLoaded));

  const isIncludeAllLangRelatedDataActivities = [ActivityTypes.ActivityPlanning];

  // if (activity?.['description'] != null) activity['description'] = getEntityFieldLocaleText(activity['description']);
  if (activity?.['name']) activity['name'] = getEntityFieldLocaleText(activity['name']);

  activity?.['metadata']?.['slideCategories']?.forEach(function (slideCatery, index, slideCategories) {
    slideCategories[index] = getEntityFieldLocaleText(slideCategories[index]);
  });

  activity?.['metadata']?.['slides']?.forEach((slide, index, slides) => {
    if (slide.category) slides[index]['category'] = getEntityFieldLocaleText(slides[index]['category']);
    if (slide.phrases && !isIncludeAllLangRelatedDataActivities.includes(activity.type.name)) {
      slide.phrases.forEach((phrase, index, phrases) => {
        phrases[index] = getEntityFieldLocaleText(phrases[index]);
      });
    }

    if (slide?.activityPlaningItems?.length) {
      slide.activityPlaningItems.forEach((item) => {
        item.phrases.forEach((phrase, index, phrases) => {
          phrases[index] = getEntityFieldLocaleText(phrases[index]);
        });
      });
    }
    if (slide?.weeklyScheduleItems?.length) {
      slide.weeklyScheduleItems.forEach((item) => {
        item.phrases.forEach((phrase, index, phrases) => {
          phrases[index] = getEntityFieldLocaleText(phrases[index]);
        });
        item.description.forEach((phrase, index, description) => {
          description[index] = getEntityFieldLocaleText(description[index]);
        });
      });
    }

    slide?.alternatePhrases?.forEach((alternatePhrase, index, alternatePhrases) => {
      alternatePhrases[index] = getEntityFieldLocaleText(alternatePhrases[index]);
    });
  });

  return activity;
}
export function getSubdomains() {
  const { i18n } = useI18n();
  const domains = i18n.value.cognishine.domains;
  return computed(() =>
    Object.values(domains)
      .map((d) => d['types'][getCurrentLanguageWithoutWebsite()])
      .flat()
  );
}
export function getSubdomainsTypesFlat() {
  const { i18n } = useI18n();

  const a = Object.values(i18n.value.cognishine.domains)
    .map((t) =>
      Object.values(t['types'][getCurrentLanguageWithoutWebsite()]).map((tt) => ({ key: tt['key'].toUpperCase(), text: tt['text'] }))
    )
    .flat();

  const types = mapValues(
    keyBy(a, (v) => v.key),
    (x) => x['text']
  );
  return types;
}

export function getFullSubdomainsFromList(fullSubdomainsList, selectedSubdomains) {
  return fullSubdomainsList.value.filter((subdomain) => {
    return selectedSubdomains.includes(keyToUrlKey(subdomain.key));
  });
}

export function getDomains() {
  const { i18n } = useI18n();
  return Object.values(i18n.value.cognishine.domains);
}

export async function getDomainActivityTypes() {
  const { loadMetadata } = useMetadata();
  let results = [];
  await loadMetadata.exec().then(() => {
    const activityTypes = ActivityType.query().newQuery().get();
    results = activityTypes;
  });
  return results;
}

export function getPricingUrl() {
  const urlPrefix = '/' + getCurrentLanguage();
  return isEntityAccessibleInCurrentLanguage(entityPerLanguages.HELPERS_GET_PRICING_URL)
    ? urlPrefix + '/pricing'
    : urlPrefix + '/contact-us';
}
export async function getActivityTypes() {
  const { loadMetadata } = useMetadata();
  let results = [];
  await loadMetadata.exec();
  const activityTypes = ActivityType.query()
    .with('domain')
    .all()
    .sort((firstType, secondType) => {
      if (firstType['new'] && !secondType['new']) return -1;
      if (!firstType['new'] && secondType['new']) return 1;
      if (firstType['domain']['sortOrder'] > secondType['domain']['sortOrder']) return -1;
      if (firstType['domain']['sortOrder'] < secondType['domain']['sortOrder']) return 1;
      if (firstType['sortOrder'] > secondType['sortOrder']) return -1;
      if (firstType['sortOrder'] < secondType['sortOrder']) return 1;
      return 0;
    });
  results = activityTypes;
  return results;
}

export async function getCategoriesData() {
  const { loadMetadata } = useMetadata();
  let results = [];
  await loadMetadata.exec().then(() => {
    const subCategories = SubCategory.query().with('category').get();

    const filterSubCategories = subCategories.filter((data) => (data as any).category);

    const subCategoriesGrouped = groupBy(filterSubCategories, (subCat: any) => {
      return (subCat?.category as any)?._id;
    });

    let result = keys(subCategoriesGrouped).map((k) => {
      const category = Category.find(k) as any;

      return {
        key: getEntityFieldLocaleText(category?.name),
        weight: category.weight,
        value: { category: category._id },
        children: orderBy(
          subCategoriesGrouped[k].map((ik: any) => ({
            key: getEntityFieldLocaleText(ik.name),
            weight: ik.weight,
            value: { subCategory: ik._id },
          })),
          (v) => v.weight
        ),
      };
    });
    result = orderBy(result, (v) => v.weight);
    results = result;
  });

  return results;
}

export function generateSurveyResults(surveyData, answers, user) {
  const results = Object.keys(answers.value).map((questionId) => {
    const question = surveyData.value.questions.filter((question) => question._id === questionId)[0];
    return {
      surveyId: new ObjectID(surveyData.value._id),
      surveyHeader: surveyData.value.header,
      surveyName: surveyData.value.name,
      userId: user.value._id,
      userEmail: user.value.email,
      questionId: new ObjectID(question['_id']),
      questionText: question['text'],
      questionType: question['type'],
      questionAnswerValue: Array.isArray(answers.value[questionId]['data'])
        ? answers.value[questionId]['data'].join(',')
        : answers.value[questionId]['data'],
      questionAnswerAdditionalText: Array.isArray(answers.value[questionId]['text'])
        ? answers.value[questionId]['text'][0]
        : answers.value[questionId]['text'],
    };
  });
  return results;
}

export function isCookiesBannerShown() {
  return document.getElementById('iubenda-cs-banner') !== null;
}

export function rangeBetween(min: any = 1, max: any = 1) {
  return (Array(max - min + 1) as any).fill().map((_, idx) => min + idx);
}

export function getValidationRules() {
  const { i18n } = useI18n();

  return {
    name: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => !!v.trim() || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => v.length >= 2 || i18n.value.cognishine.auth.login.nameTooShort,
      (v: string) => v.length <= 50 || i18n.value.cognishine.auth.login.nameTooLong,
      (v: string) =>
        /^([A-z\u0590-\u05fe\u0600-\u06fe]{2,50}([A-z\u0590-\u05fe\u0600-\u06fe0-9\s])*)*$/.test(v) ||
        i18n.value.cognishine.auth.login.nameBadChar,
    ],
    OrgUserName: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => !!v.trim() || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => v.length >= 2 || i18n.value.cognishine.auth.login.nameTooShort,
      (v: string) => v.length <= 50 || i18n.value.cognishine.auth.login.nameTooLong,
      (v: string) =>
        /^([A-z\u0590-\u05fe\u0600-\u06fe]{2,50}([A-z\u0590-\u05fe\u0600-\u06fe0-9\s])*)*$/.test(v) ||
        i18n.value.cognishine.auth.login.nameBadChar,
    ],
    password: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.passwordRequired,
      (v: string) =>
        /^(?=.*[0-9])(?=.*[a-zA-Z\u0590-\u05fe\u0600-\u06fe])([a-zA-Z\u0590-\u05fe\u0600-\u06fe0-9@$!%*?&]{8,})$/.test(v) ||
        i18n.value.cognishine.auth.login.badPasswordMessage,
    ],
    email: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.emailRequired,
      (v: string) => v.length <= 255 || i18n.value.cognishine.auth.login.emailTooLong,
      (v: string) =>
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || i18n.value.cognishine.auth.login.emailNotValid,
    ],
    coupon: [
      // (v: string) => !!v || 'Coupon code is required',
      // (v: string) => !!v.trim() || 'Coupon code is required',
      (v: string) => v.length >= 2 || 'Promo code is incorrect',
      // (v: string) => v.length <= 20 || 'Coupon code must be less than 20 characters',
      (v: string) => /^[^-\s][a-zA-Z0-9_\s-]+$/.test(v) || 'Promo code is incorrect',
    ],
    nameContact: [
      (val) => (val || '').length > 0 || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => v.length > 2 || i18n.value.cognishine.auth.login.nameTooShort,
    ],
    subject: [(val) => (val || '').length > 0 || i18n.value.cognishine.contactUs.form.subjectRequired],
    message: [
      (val) => (val || '').length > 0 || i18n.value.cognishine.contactUs.form.messageRequired,
      (v: string) => v.length > 10 || i18n.value.cognishine.contactUs.form.messageTooShort,
      (v: string) => v.length < 300 || i18n.value.cognishine.contactUs.form.messageTooLong,
    ],
    occupation: [(v: string) => !!v || i18n.value.cognishine.organization.user.addUser.validationMsgs.occupationRequired],
    recordId: [
      (v: string) => !!v || i18n.value.cognishine.organization.client.addClient.validationMsgs.recordIdRequired,
      (v: string) => !(v?.length <= 1) || i18n.value.cognishine.organization.client.addClient.validationMsgs.recordIdTooShort,
    ],
    clientName: [
      (v: string) => !!v || i18n.value.cognishine.organization.client.addClient.validationMsgs.nameIdRequired,
      (v: string) => !(v?.length <= 1) || i18n.value.cognishine.organization.client.addClient.validationMsgs.nameTooShort,
    ],
    boardName: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => !!v.trim() || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => v.length >= 2 || i18n.value.cognishine.auth.login.nameTooShort,
      (v: string) => v.length <= 50 || i18n.value.cognishine.auth.login.nameTooLong,
    ],
    phoneticsListName: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => !!v.trim() || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => v.length >= 2 || i18n.value.cognishine.auth.login.nameTooShort,
      (v: string) => v.length <= 50 || i18n.value.cognishine.auth.login.nameTooLong,
    ],
    activityName: [
      (v: string) => !!v || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => !!v.trim() || i18n.value.cognishine.auth.login.nameRequired,
      (v: string) => v.length >= 2 || i18n.value.cognishine.auth.login.nameTooShort,
      (v: string) => v.length <= 20 || i18n.value.cognishine.auth.login.nameTooLong,
    ],
    termsAndCondition: [(v: string) => !!v || ''],
    privacePolicy: [(v: string) => !!v || ''],
  };
}

export function xsDownBreakpoint(vuetify) {
  return vuetify.breakpoint.smAndDown && !vuetify.breakpoint.sm;
}

export const getPricingOrContactRoute = () => {
  return getCurrentLanguageWithoutWebsite() === ALLOWED_LANGUAGES.en ? '/pricing' : '/contact-us';
};

export const getLevelsFromAlphabates = (alphabets) => {
  let levels = [];

  for (const el of Array.isArray(alphabets) ? alphabets : [alphabets]) {
    el === 'null' || el === null ? (levels = [...levels, null]) : (levels = [...levels, ...ACTIVITY_ENUM.levels[el]]);
  }

  return levels;
};

export const isActivityPublishedInLanguage = (publishedActivity) => {
  return publishedActivity?.find((lang) => `${lang?.language?.languageCode}-${lang?.website}` === `${getCurrentLanguage(true)}`)
    ? true
    : false;
};

export const isActivityAvailableInLanguage = (languages) => {
  return languages?.includes(getCurrentLanguageWithoutWebsite());
};

export const isActivityAvailableForPublic = (languages) => {
  return languages?.includes(getCurrentLanguageWithoutWebsite());
};

export const isActivitySupportInCurrentLanguage = (activity) => {
  if (isAdminUser() && isActivityAvailableInLanguage(activity?.languageCode) && isActivityPublishedInLanguage(activity?.published)) {
    return true;
  }
  if (
    isActivityAvailableInLanguage(activity?.languageCode) &&
    isActivityAvailableForPublic(activity?.publishedToLanguage) &&
    isActivityPublishedInLanguage(activity?.published)
  ) {
    return true;
  }
  return false;
};

export const isActivityAvailableInCurrentLanguage = (activity) => {
  if (isAdminUser() && isActivityAvailableInLanguage(activity?.languageCode) && activity?.published.includes(getCurrentWebsiteId())) {
    return true;
  }
  if (
    isActivityAvailableInLanguage(activity?.languageCode) &&
    isActivityAvailableForPublic(activity?.publishedToLanguage) &&
    activity?.published.includes(getCurrentWebsiteId())
  ) {
    return true;
  }
  return false;
};

export const isUserDefinedActivityAvailableInCurrentLanguage = (activity) => {
  return isActivityAvailableInLanguage(activity.languageCode);
};

export const addOverflowYToHiddenInHtml = () => {
  // when you use this function it will remove main app level scroll so it's important to use removeOverflowYToHiddenInHtml function at end of component or somewhere else to re enable app level scroll (default behavior)
  document.getElementsByTagName('html')?.[0]?.classList.add('custom-overflow-y-hidden');
};

export const removeOverflowYToHiddenInHtml = () => {
  document.getElementsByTagName('html')?.[0]?.classList.remove('custom-overflow-y-hidden');
};

export const isClientContext = () => {
  const { client } = useState('auth', ['client']);
  return client.value !== null;
};

export const isClalitUser = () => {
  const { get } = useStorage();
  return get(StorageTypes.IS_CLALIT_USER) === true;
};

export const getClalitUserEmail = () => {
  const { get } = useStorage();
  return get(StorageTypes.CLALIT_USER_EMAIL) ? get(StorageTypes.CLALIT_USER_EMAIL) : null;
};

export const getAlphabetLevel = (level) => {
  const levelsInAlphabetsArr = flatten(Object.keys(ACTIVITY_ENUM.levels).map((el) => [el, `${el}+`]));
  const levelsInNumbers = flatten(Object.values(ACTIVITY_ENUM.levels));

  const levelsInAlphabets = {};
  levelsInNumbers.forEach((key, i) => (levelsInAlphabets[key] = levelsInAlphabetsArr[i]));

  return levelsInAlphabets[level];
};

export const isEntityAccessibleInCurrentLanguage = (getEntityPerLanguages) => {
  if (isAdminUser()) {
    const accessibleCurrentLanguages = [
      ...new Set([
        ...(setAccessPerLanguage?.[getEntityPerLanguages]?.forAdminUser || []),
        ...(setAccessPerLanguage?.[getEntityPerLanguages]?.forNoneAdminUser || []),
      ]),
    ];

    return accessibleCurrentLanguages?.includes(getCurrentLanguageWithoutWebsite());
  }
  return setAccessPerLanguage?.[getEntityPerLanguages]?.forNoneAdminUser.includes(getCurrentLanguageWithoutWebsite());
};

export const isNotLoggedInUser = () => {
  const { user: currentUser } = useState('auth', ['user']);
  return !currentUser.value;
};

export const isInHomePractice = () => {
  const { route } = useRouter();
  return route.value.name === 'home-practice';
};

export const getHomePracticeBrowserId = () => {
  const { get } = useStorage();

  return isInHomePractice() ? get(StorageTypes.HOME_PRACTICE_BROWSER_ID) : null;
};

export const getUserSelfPracticeSettings = () => {
  const { user } = useState('auth', ['user']);
  if (isUserHasOrganization()) {
    return user?.value?.organization?.selfPractice;
  }
  return user?.value?.selfPractice;
};

export const logoutMe = async (redirectURL?: string | null) => {
  const { user } = useState('auth', ['user']);

  raiseGtagEventAtLogout();

  const userId = user.value?._id;

  if (isClalitUser() && getClalitUserEmail()) {
    const res = await logutFromClalit('clalit', { email: getClalitUserEmail() });
    if (res.status === 200 && res.data.link) {
      window.location.href = res.data.link;
      logout(redirectURL);
    }
  } else {
    logout(redirectURL);
  }
  userAuditLogs({ user: userId, actionType: ACTION_TYPE_ENUM.LOGOUT });
};

export const getActivityNameFromId = (domains, domainId, activityTypeId) => {
  const activitesFromDomain = Object.values(
    domains.find((domain) => domainId === (domain as any).id)?.['types']?.[getCurrentLanguageWithoutWebsite()] || []
  );

  return activitesFromDomain.find((activityType) => (activityType as any).id === activityTypeId)?.['text'] || '';
};

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

export const removeConfetti = () => {
  const element = document.getElementById?.('largeConfetti');
  element?.remove();
};

export const removeSmallConfetti = () => {
  const element = document.getElementById?.('my-canvas');
  element?.remove();
};

export const showLargeConfetti = (duration = 1 * 1000, canvas = null, audioType = null) => {
  if (userHasShowExplosionsFeedbacks()) {
    const canvas = document.body;
    const myCanvas = document.createElement('canvas');
    myCanvas.setAttribute('id', 'largeConfetti');
    myCanvas.style.zIndex = 210;
    myCanvas.style.height = `100%`;
    myCanvas.style.width = `100%`;
    myCanvas.style.position = 'fixed';
    myCanvas.style.left = `0px`;
    myCanvas.style.top = `0px`;
    myCanvas.style.pointerEvents = `none`;
    canvas.appendChild(myCanvas);

    const myConfetti = confetti.create(myCanvas, {
      resize: true,
    });

    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 60, spread: 360, ticks: 90, zIndex: 999 };

    if (audioType && userHasPlayFeedbackSounds()) {
      PlayRewardSound(null, audioType);
    }

    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      myConfetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
      myConfetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
    }, 250);
  }
};

export const playSilentSound = async (audioWantToPlayNext) => {
  const fullVolume = 1.0;
  try {
    if (userHasPlayFeedbackSounds()) {
      // Save the previous volume if we're doing an initial play
      const previousVolume = audioWantToPlayNext?.volume;

      // Mute the audio for initial play
      audioWantToPlayNext.muted = true;

      audioWantToPlayNext?.play();

      // Add an event listener for when the audio finishes playing
      const onEnded = () => {
        // Unmute and restore volume after the audio has finished playing
        audioWantToPlayNext.muted = false;
        audioWantToPlayNext.volume = previousVolume || fullVolume;
        audioWantToPlayNext?.removeEventListener('ended', onEnded);
      };

      audioWantToPlayNext?.addEventListener('ended', onEnded);

      return audioWantToPlayNext;
    }
  } catch (error) {
    console.warn("Audio doesn't exists.");
  }
};

export const PlayRewardSound = (currentPlayingAudio = null, audioWantToPlayNext, loop = false) => {
  try {
    if (userHasPlayFeedbackSounds()) {
      if (currentPlayingAudio) {
        currentPlayingAudio.pause();
        currentPlayingAudio.currentTime = 0;
      }

      // Set loop if required
      if (loop) {
        audioWantToPlayNext.loop = true;
      }

      audioWantToPlayNext?.play();

      return audioWantToPlayNext;
    }
  } catch (error) {
    console.warn("Audio doesn't exists.");
  }
};

export const stopRewordSound = (currentPlayingAudio) => {
  if (currentPlayingAudio) {
    Object.values(currentPlayingAudio).forEach((sound) => {
      console.log({ sound });
      (sound as any).currentTime = 0;
      (sound as any).pause();
    });
  }
};

const loadSoundWithEvent = (audio) => {
  return new Promise((resolve) => {
    audio.addEventListener('canplaythrough', resolve, { once: true });
    audio.load();
  });
};

export const loadedRewardSound = {};

export const loadAllRewardSounds = async () => {
  const availablePlaySound = {
    successSound: `${environment.cdn}/resources/sounds/correct.mp3${forceUpdate()}`,
    failaureSound: `${environment.cdn}/resources/sounds/wrong.mp3${forceUpdate()}`,
    largeConfettiSound: `${environment.cdn}/resources/sounds/confetti_large.mp3${forceUpdate()}`,
    smallConfettiSound: `${environment.cdn}/resources/sounds/confetti_small.mp3${forceUpdate()}`,
    messageReceived: `${environment.cdn}/resources/sounds/message_recieved.mp3${forceUpdate()}`,
    phoneRing: `${environment.cdn}/resources/sounds/phone_ring.mp3${forceUpdate()}`,
  };
  const availablePlaySoundObject = {};

  for (const soundType in availablePlaySound) {
    const audio = new Audio(availablePlaySound[soundType]);
    audio.preload = 'auto';
    await loadSoundWithEvent(audio);
    availablePlaySoundObject[soundType] = audio;
    loadedRewardSound[soundType] = audio;
  }
  return availablePlaySoundObject;
};

export const showSmallConfetti = ({ height, width, x, y, top, left, right, bottom }, audioType = false) => {
  if (userHasShowExplosionsFeedbacks()) {
    const canvas = document.querySelector('.dialog--activity-game');
    const myCanvas = document.createElement('canvas');
    myCanvas.setAttribute('id', 'my-canvas');
    myCanvas.style.position = 'fixed';

    myCanvas.style.height = `${height}px`;
    myCanvas.style.width = `${width}px`;
    myCanvas.style.top = `${top}px`;
    myCanvas.style.bottom = `${bottom}px`;
    myCanvas.style.left = `${left}px`;
    myCanvas.style.right = `${right}px`;
    myCanvas.style.x = `${x}px`;
    myCanvas.style.y = `${y}px`;
    myCanvas.style.zIndex = 9999;

    canvas.appendChild(myCanvas);

    myCanvas.confetti = myCanvas.confetti || confetti.create(myCanvas, { resize: true });

    const count = 200;
    const defaults = {
      origin: { y: 0.7, height, width },
    };
    console.log({ audioType });
    if (audioType && userHasPlayFeedbackSounds()) {
      PlayRewardSound(null, audioType);
    }
    const fire = (particleRatio, opts) => {
      myCanvas.confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        })
      );
    };
    setTimeout(() => {
      const element = document.getElementById?.('my-canvas');

      element?.remove();
    }, 3000);

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }
};

export const autoLogoutOnBrowserClose = (user = null) => {
  if (isUserHasOrganization(user)) {
    return (
      user?.organization?.autoLogoutOnBrowserClose ||
      user?.organization?.autoLogout ||
      user?.organization?.sessionMaxTime ||
      !user?.preferences?.keepMeLoggedIn
    );
  } else {
    return !user?.preferences?.keepMeLoggedIn;
  }
};

export const raiseGtagEvent = (eventName = '', eventParams = null) => {
  const gtag = Vue.prototype.$gtag;
  try {
    gtag?.event(eventName, {
      website: getCurrentLanguage(false),
      ...(eventParams && eventParams),
    });
  } catch (error) {
    console.log('ERROR WHILE RAISED GTAG EVENT :  ', error);
  }
};

export const setUserData = () => {
  const { user: currentUser } = useState('auth', ['user']);
  const gtag = Vue.prototype.$gtag;

  gtag?.set('user_properties', { user_id_dim: currentUser.value?._id });
  gtag?.set('user_properties', { org_id_dim: currentUser.value?.organization?._id });
  gtag?.set('user_properties', { user_id: currentUser.value?._id });
  gtag?.set({ user_id: currentUser.value?._id });
  gtag?.set('user_properties', { website_dim: getCurrentLanguage(false) });
};

export const raiseGtagEventAtLogin = (eventName = '', eventParams = null) => {
  setUserData();
  raiseGtagEvent(eventName, eventParams);
};

export const raiseGtagEventAtLogout = (eventName = '', eventParams = null) => {
  const gtag = Vue.prototype.$gtag;
  gtag?.set('user_properties', { org_id_dim: null });
  gtag?.set('user_properties', { user_id_dim: null });
  gtag?.set('user_properties', { user_id: null });
  gtag?.set({ user_id: null });
  raiseGtagEvent(eventName, eventParams);
};

export const raiseGtagEventAtCustomPageLoad = (eventName = '', eventParams = null) => {
  const gtag = Vue.prototype.$gtag;
  gtag?.set('user_properties', { website_dim: getCurrentLanguage(false) });
  raiseGtagEvent(eventName, eventParams);
};

export const isUserHasCurrentWebsiteAccessInOrg = () => {
  const { user } = useState('auth', ['user']);
  if (isUserHasOrganization()) {
    return user.value?.organization?.websiteAccess?.includes(getCurrentWebsiteId(false));
  }
  return false;
};

export const forceUpdate = () => {
  return `?GUID=${moment().valueOf()}`;
};

export const isImageBasedActivity = (mediaType) => {
  return [ACTIVITY_ENUM.mediaType.photo, ACTIVITY_ENUM.mediaType.isolatePhoto, ACTIVITY_ENUM.mediaType.illustration].includes(mediaType);
};

export const getCurrentLanguageId = () => {
  const availableLang = getLanguages();
  if (availableLang.length) {
    return getCurrentLanguageObject()?.language?._id;
  }
  return null;
};

export const getTextCardStyleSettings = (tileWidth, customGridTileStyleSettings = null) => {
  const tileStyleSettings = customGridTileStyleSettings ? customGridTileStyleSettings : textCardStyleSettings;

  for (const { width, fontSize, padding } of tileStyleSettings) {
    if (tileWidth < width) {
      return { tileFontSize: fontSize, tilePadding: padding };
    }
  }

  return {
    tileFontSize: DEFAULT_TEXT_CARD_STYLE_SETTING_ENUM.defaultFontSize,
    tilePadding: DEFAULT_TEXT_CARD_STYLE_SETTING_ENUM.defaultGridTilePadding,
  };
};

export const currentScreenForwardAnimation = async (titleRef, contentRef, footerRef) => {
  titleRef.classList.add('fadeout');

  isCurrentLanguageRTL() ? contentRef.classList.add('slide-left-reverse') : contentRef.classList.add('slide-left');
  await timeout(350);
  titleRef.classList.remove('fadeout');
  isCurrentLanguageRTL() ? contentRef.classList.remove('slide-left-reverse') : contentRef.classList.remove('slide-left');
  contentRef.classList.add('opacity-0');
  titleRef.classList.add('opacity-0');
  footerRef.classList.add('opacity-0');
};

export const nextScreenForwardAnimation = async (titleRef, contentRef, footerRef) => {
  await timeout(20);

  titleRef.classList.add('fadeIn');
  titleRef.classList.remove('opacity-0');

  contentRef.classList.add('opacity-0');
  isCurrentLanguageRTL() ? contentRef.classList.add('slide-right-reverse') : contentRef.classList.add('slide-right');

  await timeout(350);

  titleRef.classList.remove('fadeIn');
  contentRef.classList.remove('opacity-0');
  isCurrentLanguageRTL() ? contentRef.classList.remove('slide-right-reverse') : contentRef.classList.remove('slide-right');
  await timeout(20);
  footerRef.classList.add('fadeIn');
  footerRef.classList.remove('opacity-0');
  await timeout(300);
  footerRef.classList.remove('fadeIn');
};

export const currentScreenBackwardAnimation = async (titleRef, contentRef, footerRef) => {
  footerRef?.classList?.add('fadeout');
  await timeout(300);
  footerRef?.classList?.add('opacity-0');

  isCurrentLanguageRTL() ? contentRef?.classList?.add('slide-left-reverse') : contentRef?.classList?.add('slide-left');
  await timeout(350);
  contentRef?.classList.add('opacity-0');

  footerRef?.classList.remove('fadeout');

  isCurrentLanguageRTL() ? contentRef?.classList?.remove('slide-left-reverse') : contentRef?.classList?.remove('slide-left');
  titleRef?.classList?.add('fadeout');
  await timeout(400);

  titleRef?.classList?.remove('opacity-0');
};

export const nextScreenBackwardAnimation = async (titleRef, contentRef, footerRef) => {
  await timeout(20);

  contentRef?.classList.add('opacity-0');
  isCurrentLanguageRTL() ? contentRef.classList.add('slide-right-reverse') : contentRef.classList.add('slide-right');
  await timeout(350);

  titleRef?.classList.remove('fadeIn');
  contentRef?.classList.remove('opacity-0');
  isCurrentLanguageRTL() ? contentRef?.classList.remove('slide-right-reverse') : contentRef?.classList.remove('slide-right');

  footerRef?.classList.add('fadeIn');
  footerRef?.classList.remove('opacity-0');
  await timeout(300);
  footerRef?.classList.remove('fadeIn');
};

const getCustomCursorUrl = (cursorType: CURSOR_TYPE, cursorSize: CURSOR_SIZE, cursorImage, isRootCursor = false) => {
  let url = `url(${cursorImage})`;

  if (cursorType === CURSOR_TYPE.CIRCLE && cursorSize === CURSOR_SIZE.MEDIUM) url = `${url} 26 26`;

  if (cursorType === CURSOR_TYPE.CIRCLE && cursorSize === CURSOR_SIZE.SMALL) url = `${url} 14 14`;

  if (isRootCursor) url = `${url}, auto`;

  return url;
};

export const getActivityInstructionsForHomePractice = (activity) => {
  const { i18n } = useI18n();

  try {
    const activityToInstructions = Object.values(i18n.value.cognishine.domains)
      .filter((t) => t['key']?.toUpperCase() === activity.domain?.toUpperCase())
      .map((t) =>
        Object.values(t['types'][getCurrentLanguageWithoutWebsite()]).map((tt) => ({
          key: tt['key'].toUpperCase(),
          instruction: tt['instruction'],
        }))
      )
      .flat();

    if (activity.isUserDefined) {
      const activityType = activity?.activityType ? activity?.activityType?.name : activity?.type?.name;
      return activityToInstructions.filter((t) => t['key'] === activityType?.toUpperCase())[0].instruction;
    } else {
      return activityToInstructions.filter((t) => t['key'] === activity.type.toUpperCase())[0].instruction;
    }
  } catch (e) {
    return undefined;
  }
};

export const setCustomCursorPropertyVariable = () => {
  const { cursorSettings } = useState('app', ['cursorSettings']);

  const cursorImage =
    customCursorImage?.color?.[cursorSettings.value.cursorType]?.[cursorSettings.value.size]?.[cursorSettings.value.selected];
  const root = document.documentElement;

  root.style.setProperty(
    '--cursor-type-custom',
    getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage)
  );
  root.style.setProperty(
    '--cursor-type-pointer',
    getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage)
  );
  root.style.setProperty('--cursor-type-move', getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage));
  root.style.setProperty(
    '--cursor-type-default',
    getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage)
  );
  root.style.setProperty('--cursor-type-auto', getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage));
  root.style.setProperty('--cursor-type-hand', getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage));
  root.style.setProperty('--cursor-type-text', getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage));

  root.children[1].classList.add(`custom-cursor-mode`);
  root.children[1].classList.add(`cursor-${cursorSettings.value.cursorType}`);

  /*sets root element cursor */
  root.style.cursor = getCustomCursorUrl(cursorSettings.value.cursorType, cursorSettings.value.size, cursorImage, true);
};

export const removeCustomCursorPropertyVariable = () => {
  const { cursorSettings } = useState('app', ['cursorSettings']);
  const root = document.documentElement;
  root.style.removeProperty('--cursor-type-custom');
  root.style.removeProperty('--cursor-type-pointer');
  root.style.removeProperty('--cursor-type-move');
  root.style.removeProperty('--cursor-type-default');
  root.style.removeProperty('--cursor-type-auto');
  root.style.removeProperty('--cursor-type-hand');
  root.style.removeProperty('--cursor-type-text');
  root.children[1].classList.remove('custom-cursor-mode');
  root.children[1].classList.remove(`cursor-${cursorSettings.value.cursorType}`);
  root.style.removeProperty('cursor');
};

export const calculateUserDefinedActivityItemsCount = (activity) => {
  return activity.activityInfo.memoryCardInfo?.layout?.rows * activity.activityInfo.memoryCardInfo?.layout?.cols;
};

export const getUserDefinedActivityType = (activity, i, t) => {
  const activityType = activity?.activityType ? activity?.activityType?.name : activity?.type?.name;
  if (activityType === ActivityTypes.MemoryPairsStudio) {
    return t(`${i.value.cognishine.activities.memoryPairsStudio.customActivityType}`, { numPairs: activity.data.length }).value;
  }
  if (activityType === ActivityTypes.FlashCardsStudio) {
    return t(`${i.value.cognishine.activities.flashCardsStudio.customActivityType}`, { numSlides: activity.data.length }).value;
  }
};

export const isUserLoggedIn = () => {
  const { user } = useState('auth', ['user']);
  return user.value !== null && user.value !== undefined;
};

export const getSortedItems = (activities, orderList: string[], sortKey = 'id') => {
  const idIndexMap: Map<string, number> = new Map(orderList.map((id, index) => [id, index]));

  activities.sort((a, b) => {
    const indexA: number | undefined = idIndexMap.get(a[sortKey]);
    const indexB: number | undefined = idIndexMap.get(b[sortKey]);
    if (indexA === undefined && indexB === undefined) return 0;
    if (indexA === undefined) return 1;
    if (indexB === undefined) return -1;

    return indexA - indexB;
  });

  return activities;
};

export const updateHeartbeatSession = (prevUserId, prevClientId, previousHomePracticeId) => {
  const { save } = useStorage();
  const id = uuidv4();
  save(StorageTypes.HEART_BEAT_SESSION_ID, id, true);
  const prevUserIdToSave = prevUserId ? prevUserId : 1;
  const prevClientIdToSave = prevClientId ? prevClientId : 1;
  const previousHomePracticeIdToSave = previousHomePracticeId ? previousHomePracticeId : 1;
  save(StorageTypes.PREVIOUS_USER_ID, prevUserIdToSave, true);
  save(StorageTypes.PREVIOUS_CLIENT_ID, prevClientIdToSave, true);
  save(StorageTypes.PREVIOUS_HOME_PRACTICE_ID, previousHomePracticeIdToSave, true);
};

// INFO: heart beat related helpers
export const userOrClientChangedOrHomePracticeIDChanged = (currentUser, currentClient, currentHomePracticeId) => {
  const { get } = useStorage();
  const prevUserUId = get(StorageTypes.PREVIOUS_USER_ID);
  const prevClientId = get(StorageTypes.PREVIOUS_CLIENT_ID);
  const prevHomePracticeId = get(StorageTypes.PREVIOUS_HOME_PRACTICE_ID);
  //user changed
  const currentUserId = currentUser?.value?._id ? currentUser?.value?._id : 1;
  if (currentUserId !== prevUserUId) {
    return true;
  }
  //client changed
  const currentClientId = currentClient?.value?._id ? currentClient?.value?._id : 1;
  if (currentClientId !== prevClientId) {
    return true;
  }
  currentHomePracticeId = currentHomePracticeId ? currentHomePracticeId : 1;
  if (currentHomePracticeId !== prevHomePracticeId) {
    return true;
  }

  return false;
};

export const setLastUserInteractionTimeHeartBeat = (time) => {
  const { save } = useStorage();
  save(StorageTypes.LAST_USER_INTERACTION_TIME, time, true);
};

export const getLastUserInteractionTimeHeartBeat = () => {
  const { get } = useStorage();
  return get(StorageTypes.LAST_USER_INTERACTION_TIME);
};
// Example usage:
// const headers = {
//   id: "ID",
//   name: "Name",
//   age: "Age"
// };

// const data = [
//   { ID: 1, Name: "Alice", Age: 25 },
//   { ID: 2, Name: "Bob", Age: 30 },
//   { ID: 3, Name: "Charlie", Age: 35 }
// ];

// createCSV(headers, data);

export const createAndExportCSV = (headers, data) => {
  console.log(headers);
  console.log(data);
  // Convert the headers object to an array of header titles
  const headerTitles = Object.keys(headers);

  // Convert the data object to an array of arrays, each representing a row of data
  const rows = data.map((row) => {
    return headerTitles.map((header) => row[headers[header]]);
  });

  // Combine the headers and rows into a single array
  let csvContent = [headerTitles, ...rows].map((e) => e.join(',')).join('\n');
  csvContent = '\ufeff' + csvContent;
  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a link element to trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'data.csv');

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up by removing the link
  document.body.removeChild(link);
};

export const getSortedBoardItems = (activities, userActivities, orderList) => {
  let items = [...activities, ...userActivities];
  items = getSortedItems(items, orderList, '_id');
  return items;
};

export const calculateBorderWidth = (tileHeight, tileWidth) => {
  const maxBorderWidth = 10;
  const minBorderWidth = 3;
  let borderWidth = tileHeight * 0.05;
  if (borderWidth < minBorderWidth) borderWidth = minBorderWidth;
  else if (borderWidth > maxBorderWidth) borderWidth = maxBorderWidth;
  return borderWidth;
};

export const hasPhysicalKeyboard = async () => {
  const parser = new UAParser();
  const device = parser.getResult(); // Assuming getResult() gives you the parsed result
  const type = device.device?.type; // Use optional chaining here

  if (!type) return true; // Likely a desktop/laptop or parsing failed
  if (type === 'mobile' || type === 'tablet') return false;

  // Step 2: Use `navigator.keyboard` if available
  if ('keyboard' in navigator && typeof (navigator as any)?.keyboard?.getLayoutMap === 'function') {
    if ((navigator as any)?.keyboard && (navigator as any).keyboard?.getLayoutMap) {
      try {
        const layoutMap = await (navigator as any)?.keyboard.getLayoutMap();
        if (layoutMap) return true;
      } catch {
        console.warn('Error accessing keyboard layout map.');
      }
    }
  }

  // Step 3: Detect `keydown` events
  let isKeyboardUsed = false;
  const detectKeyboardUsage = () => {
    isKeyboardUsed = true;
    window.removeEventListener('keydown', detectKeyboardUsage);
  };
  window.addEventListener('keydown', detectKeyboardUsage);

  // Wait for user interaction
  await new Promise((resolve) => setTimeout(resolve, 1000));

  return isKeyboardUsed;
};

export const areArraysEqual = (arr1, arr2) => {
  // Check if arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Compare each element using `every`
  return arr1.every((value, index) => value === arr2[index]);
};
